import * as React from 'react'
import { Link } from 'react-router-dom'

import Button from './Button'

type Modifiers = Partial<{
  accent: 'blue' | 'green'
  primary: boolean
  secondary: boolean
  hidden: boolean
  tertiary: boolean
}>

export type ActionButtonProps = Modifiers & {
  action?: () => void
  block?: boolean
  children?: JSX.Element
  className?: string
  href?: string
  disabled?: boolean
  hidden?: boolean
  key?: string
  text: string | JSX.Element
}

const primarySecondaryStyles = `font-inter text-pale-gray2 text-s font-bold rounded-lg min-w-[95px] py-[7px] px-5`

const commonStyles = 'disabled:opacity-50 text-center !text-s'

const primaryGreenStyles = 'bg-apple-green text-pale-gray2'

const primaryBlueStyles = 'bg-brand-600 text-pale-gray2'

const secondaryStyles = 'bg-pale-gray border border-gray-600 !text-gray-700 text-semibold'

const tertiaryStyles = 'text-brand-700 font-inter text-xs font-normal p-0'

const hiddenStyles = 'invisible'

const getStyles = ({ accent = 'blue', primary, secondary, tertiary, hidden }: Modifiers) => {
  if (primary) {
    return `${commonStyles} ${primarySecondaryStyles} ${
      accent === 'green' ? primaryGreenStyles : primaryBlueStyles
    } ${hidden ? hiddenStyles : ''}`
  }
  if (secondary) {
    return `${commonStyles} ${primarySecondaryStyles} ${secondaryStyles} ${
      hidden ? hiddenStyles : ''
    }`
  }
  if (tertiary) {
    return `${commonStyles} ${tertiaryStyles} ${hidden ? hiddenStyles : ''}`
  }
}

const ActionButton = ({
  action,
  block,
  children,
  className,
  disabled,
  href,
  text,
  ...modifiers
}: ActionButtonProps) =>
  href ? (
    <Link className={`${getStyles(modifiers)} ${className ?? ''}`} to={href}>
      {children ?? text}
    </Link>
  ) : (
    <Button
      active={!disabled}
      handleClick={action}
      tw={`${getStyles(modifiers)} ${className ?? ''}`}
    >
      {children ?? text}
    </Button>
  )

export { getStyles }
export default ActionButton
