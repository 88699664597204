import React from 'react'
import { animated, useSpring, useTransition } from 'react-spring'

import Menu from '../../../components/Menu'
import { getTextWidth } from '../../../utils/getTextWidth'
import { X } from '../../icons/v5/remove'

type WorkflowAutomationFilterMenuProps<T extends string> = {
  startsWith?: boolean
  filterValue: string
  handleClick: (value: T) => void
  options: Array<{ label: string; value: T }>
  title: string
}

const WorkflowAutomationFilterMenu = <T extends string>({
  filterValue,
  title,
  ...props
}: WorkflowAutomationFilterMenuProps<T>) => {
  const transition = useTransition(filterValue !== '', {
    enter: { opacity: 1, width: 15 },
    from: { opacity: 0, width: 0 },
    initial: null,
    leave: { opacity: 0, width: 0 },
  })

  const width = getTextWidth(filterValue || title)
  const springWidth = useSpring({ width: width + 40 + (filterValue ? 25 : 0) })

  return (
    <animated.div
      className="flex h-9 items-center justify-between rounded-lg border border-gray-300 bg-white px-[10px] py-2 [&_>_div_>_button]:whitespace-nowrap [&_>_div_>_button]:font-semibold [&_>_div_>_button]:text-gray-700 [&_>_div_>_div]:-left-[10px] [&_>_div_>_div]:top-7 [&_>_div_>_svg:hover]:opacity-70 [&_>_div_>_svg]:cursor-pointer [&_>_div_>_svg]:fill-red-500 [&_>_div_>_svg]:transition-[opacity] [&_>_div_>_svg]:duration-300 [&_>_div_>_svg]:ease-in-out"
      style={springWidth}
    >
      <Menu
        align="left"
        {...props}
        maxHeight={400}
        searchable={title}
        title={filterValue || title}
      />
      {transition(
        (style, item, { key }) =>
          item && (
            <animated.div key={key} style={style}>
              <X
                className="mr-[5px] h-4 w-4 cursor-pointer stroke-gray-500 transition-[fill] duration-300 ease-in-out hover:stroke-red-500"
                onClick={() => props.handleClick('' as T)}
                height={11}
                title="Clear filter"
                width="100%"
              />
            </animated.div>
          )
      )}
    </animated.div>
  )
}

export default WorkflowAutomationFilterMenu
