import { IntelligenceEntityType } from '@juristat/common/types'
import React from 'react'
import { Link } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import Button from '../../../components/Button'
import { twTextStyles } from '../../../styles'
import { File } from '../../icons/v5/file'
import { X } from '../../icons/v5/remove'
import { usePrefetchEntityDetails } from '../../intelligence/hooks'
import Attribute from '../../search/components/Attribute'
import { Access } from '../../session/types'
import { OmnisearchResult, OmnisearchType } from '../types'
import Omnisearch, { AdditionalSections } from './Omnisearch'
import OmnisearchByKeyword from './OmnisearchByKeyword'

type NavigationOmnisearchProps = {
  className?: string
  displayType?: 'modal' | 'page'
  focus?: boolean
  forceOpen?: boolean
  searchType?: Tab
  initialValue?: string
  setDisplayType?: React.Dispatch<React.SetStateAction<'modal' | 'page'>>
  setSearchOpen?: React.Dispatch<React.SetStateAction<boolean>>
  tw?: boolean
}

export type Tab = 'category' | 'keyword'

const styles = {
  attribute:
    `[&_>_span]:block [&_>_span]:overflow-hidden [&_>_span]:text-ellipsis ` +
    `[&:not(:last-of-type)_>_span]:mb-[5px] display:[initial] pr-[0]`,
  container: 'items-center flex justify-between h-[60px] p-[10px]',
  info: 'text-gray-900 w-[350px]',
  infoMainText: 'mb-[10px] mx-0 mt-0',
  infoSubText: 'text-sm',
  link: `${twTextStyles.linkBlueNormal13} underline`,
  logo: 'items-center flex ml-5',
  main: 'border-b border-gray-300 relative',
  search: 'left-1/2 absolute top-[10px] translate-[translateX(-50%)] z-[100]',
  searchBy: {
    active: 'border-b-3 border-brand-600 font-bold opacity-100',
    button: 'text-base py-[5px] px-5 opacity-70',
    buttons: 'flex justify-between',
    link: 'text-brand-700',
  },
  topContainer:
    '[&_>_*_i]:ml-1 [&_>_*:disabled]:opacity-50 [&_>_*:hover:not(:disabled)]:underline [&_>_*]:items-center [&_>_*]:flex [&_>_*]:justify-start [&_>_*]:text-left [&_>_*]:w-[240px] [&_>_:not(:last-child)]:mr-5 flex justify-center pt-5 px-6 pb-0',
}

const additionalSections: AdditionalSections = {
  type: 'category',
  sections: [
    [],
    [
      { icon: File, key: OmnisearchType.Application, label: 'Applications' },
      { icon: File, key: OmnisearchType.Patent, label: 'Patents' },
    ],
  ],
}

export const getLink = ({ description, id, type }: OmnisearchResult) => {
  switch (type) {
    case OmnisearchType.Application:
    case OmnisearchType.Patent:
      return { to: `application/${id.replace('pat,', '')}`, value: description }
    case OmnisearchType.ArtUnit:
      return { canAccess: Access.ArtUnit, to: `art-unit/${id}`, value: description }
    case OmnisearchType.Attorney:
      return { to: `attorney-at-disp/${id}`, value: description }
    case OmnisearchType.Company:
      return {
        canAccess: Access.BusinessIntelligence,
        to: `assignee-at-disp/${id}`,
        value: description,
      }
    case OmnisearchType.Firm:
      return {
        canAccess: Access.BusinessIntelligence,
        to: `firm-at-disp/${id}`,
        value: description,
      }
    case OmnisearchType.Examiner:
      return { canAccess: Access.Examiner, to: `examiner/${id}`, value: description }
    case OmnisearchType.TechCenter:
      return { canAccess: Access.ArtUnit, to: `tech-center/${id}`, value: description }
    case OmnisearchType.Cpc:
      return { canAccess: Access.UspcClass, to: `cpc/${id}`, value: description }
    case OmnisearchType.Uspc:
      return { canAccess: Access.UspcClass, to: `uspc/${id}`, value: description }
    case OmnisearchType.Expert:
      return { to: `expert/${id}`, value: description }
    default:
      return { to: '', value: '' }
  }
}

const typeToEntityMap = {
  [OmnisearchType.ArtUnit]: IntelligenceEntityType.ArtUnit,
  [OmnisearchType.Attorney]: IntelligenceEntityType.AttorneyAtDisposition,
  [OmnisearchType.Company]: IntelligenceEntityType.AssigneeAtDisposition,
  [OmnisearchType.Cpc]: IntelligenceEntityType.Cpc,
  [OmnisearchType.Examiner]: IntelligenceEntityType.Examiner,
  [OmnisearchType.Firm]: IntelligenceEntityType.FirmAtDisposition,
  [OmnisearchType.TechCenter]: IntelligenceEntityType.TechCenter,
  [OmnisearchType.Uspc]: IntelligenceEntityType.Uspc,
}

const Result = (result: OmnisearchResult) => {
  const link = getLink(result)
  const prefetchEntityDetails = usePrefetchEntityDetails({
    entity: typeToEntityMap[result.type],
    id: result.id,
  })
  const [prefetch] = useDebouncedCallback(prefetchEntityDetails, 200)

  return link ? (
    <Attribute
      key={JSON.stringify(result)}
      className="display:[initial] pr-[0] uppercase [&:not(:last-of-type)_>_span]:mb-[5px] [&_>_span]:block [&_>_span]:overflow-hidden [&_>_span]:text-ellipsis"
      label=""
      onMouseEnter={() => prefetch()}
      {...link}
    />
  ) : (
    <div key={JSON.stringify(result)}>{result.description}</div>
  )
}

const NavigationOmnisearch: React.FC<NavigationOmnisearchProps> = ({
  className,
  displayType,
  focus,
  forceOpen,
  searchType,
  initialValue,
  setDisplayType,
  setSearchOpen,
}) => {
  return (
    <Omnisearch
      additionalSections={additionalSections}
      className={className}
      displayType={displayType}
      forceOpen={forceOpen}
      searchType={searchType}
      initialValue={initialValue}
      inputProps={{
        autoFocus: focus,
      }}
      renderResult={(result) => <Result {...result} />}
      renderTop={() => (
        <div className={`${displayType === 'modal' ? 'pl-5' : 'px-10'} pb-0 pt-[10px]`}>
          {displayType === 'modal' ? (
            <div className="flex justify-between">
              <h1 className="py-[5px] text-lg font-semibold text-gray-900">
                {searchType === 'category' ? 'Explore' : 'Keyword Search'}
              </h1>
              {setSearchOpen ? (
                <Button onClick={() => setSearchOpen(false)} tw="mr-4 -mt-4">
                  <X />
                </Button>
              ) : null}
            </div>
          ) : (
            <div className="flex justify-center">
              <h1 className="py-[5px] text-2xl font-semibold text-gray-900">
                {searchType === 'category' ? 'Explore' : 'Keyword Search'}
              </h1>
            </div>
          )}

          <div className="my-2">
            {searchType === 'category' ? (
              <div>
                <div className={styles.topContainer}>
                  <Link to="/uspto">
                    <span className="brand-gray-900 text-sm font-bold">View the entire</span>
                    <span className="ml-1 text-sm font-bold text-[#2E90FA]">USPTO dataset</span>
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
      tw={true}
    >
      {searchType === 'keyword'
        ? (props) => (
            <OmnisearchByKeyword
              displayType={displayType}
              setDisplayType={setDisplayType}
              setSearchOpen={setSearchOpen}
              {...props}
            />
          )
        : undefined}
    </Omnisearch>
  )
}

export default NavigationOmnisearch
