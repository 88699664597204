import Downshift, { ControllerStateAndHelpers, DownshiftProps } from 'downshift'
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { useFuzzySort } from '../../hooks'
import { Chevron, Direction } from '../../modules/icons/v5/chevron'
import { X } from '../../modules/icons/v5/remove'
import { twTextStyles } from '../../styles'
import SearchTextInput from '../SearchTextInput'

type Option<T> = {
  label: string
  value: T
}

type SelectProps<T> = {
  children?: (
    props: Pick<ControllerStateAndHelpers<Option<T>>, 'getItemProps'> &
      Option<T> & {
        className: string
        index: number
      }
  ) => React.ReactNode
  className?: string
  disabled?: boolean
  handleClear?: () => void
  maxHeight?: number
  options: Array<Option<T>>
  placeholder?: string
  searchable?: boolean | string
  searchTextInputProps?: React.ComponentProps<typeof SearchTextInput>
  title?: string
  tw?: string
}

type BaseSelectProps<T> = Omit<DownshiftProps<Option<T>>, 'children'> &
  SelectProps<T> & {
    children: NonNullable<SelectProps<T>['children']>
    label: string
  }

interface DownshiftMouseEvent extends React.MouseEvent {
  nativeEvent: MouseEvent & { preventDownshiftDefault?: boolean }
}

const styles = {
  active: 'bg-cloudy-blue-alpha-20',
  arrow: 'stroke-charcoal-gray !fill-none h-[12px] w-[12px]',
  clear:
    'mr-[5px] h-5 w-5 cursor-pointer stroke-gray-500 transition-[fill] duration-300 ease-in-out hover:stroke-red-500',
  container: 'relative',
  item: `${twTextStyles.darkNormal13} cursor-pointer justify-start py-[6px] px-[14px] transition-[background-color] duration-300 ease-in-out w-full`,
  label: `text-inherit font-inter text-s not-italic pr-[5px] text-left truncate w-full`,
  filtersAppliedLabel: 'font-bold text-s pr-[5px]',
  main: 'disabled:cursor-not-allowed disabled:opacity-60 items-center !bg-white border border-gray-300 rounded-lg flex h-9 justify-between py-0 px-[10px] w-full',
  menu: 'bg-white rounded-lg shadow-[0_4px_10px_0_rgba(48,_55,_65,_0.3)] overflow-hidden px-0 py-[10px] absolute w-full z-[100] max-h-[400px] overflow-y-scroll',
  menuClosed: 'hidden',
  placeholder: `${twTextStyles.silver3Normal13} font-normal`,
  search: 'mt-0 mr-[10px] mb-[10px] ml-[10px]',
}

const BaseSelect = <T extends string | number>({
  disabled = false,
  children,
  className,
  handleClear,
  label,
  maxHeight,
  options,
  placeholder = 'Select an option',
  searchable = false,
  searchTextInputProps = {},
  title,
  tw,
  ...props
}: BaseSelectProps<T>) => {
  const [needle, setNeedle] = useState('')
  const results = useFuzzySort({
    haystack: options,
    keys: ['label'],
    needle,
  })
  const filtersAppliedLabel = label.split(',').length > 1 ? ` (${label.split(',').length})` : ''

  return (
    <Downshift itemToString={(item) => (item ? item.label : '')} {...props}>
      {({
        clearSelection,
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        highlightedIndex,
        isOpen,
      }) => (
        <div className={twMerge(styles.container, className, tw)} title={title}>
          <button {...getToggleButtonProps({ className: styles.main, disabled, title: label })}>
            {label ? (
              <span className={styles.label}>{label}</span>
            ) : (
              <span className={`${styles.label} ${styles.placeholder}`}>{placeholder}</span>
            )}
            {filtersAppliedLabel && (
              <span className={`${styles.filtersAppliedLabel} ${styles.filtersAppliedLabel}`}>
                {filtersAppliedLabel}
              </span>
            )}
            {label && handleClear ? (
              <X
                className={`${styles.clear} clear-selection`}
                onClick={(event: DownshiftMouseEvent) => {
                  event.nativeEvent.preventDownshiftDefault = true

                  clearSelection(handleClear)
                }}
                title="Clear selection"
              />
            ) : null}
            <Chevron
              className={styles.arrow}
              direction={isOpen ? Direction.Up : Direction.Down}
              title={isOpen ? 'Close' : 'Open'}
            />
          </button>
          <ul
            {...getMenuProps({
              className: `${styles.menu} ${!isOpen ? styles.menuClosed : ''} ${
                maxHeight ? `overflow-auto max-h-[${maxHeight}px]` : ''
              }`,
            })}
          >
            {searchable ? (
              <SearchTextInput
                accent="blue"
                className={styles.search}
                handleOnTextChange={setNeedle}
                placeholder={[
                  'Search',
                  typeof searchable === 'string' ? searchable : 'for an option',
                ].join(' ')}
                onClear={needle ? () => setNeedle('') : undefined}
                text={needle}
                {...searchTextInputProps}
              />
            ) : null}
            {!isOpen
              ? null
              : results.map((item, index) =>
                  children({
                    ...item,
                    className: `${styles.item} ${index === highlightedIndex ? styles.active : ''}`,
                    getItemProps,
                    index,
                  })
                )}
          </ul>
        </div>
      )}
    </Downshift>
  )
}

export { Option, SelectProps }
export default BaseSelect
