import { css } from 'emotion'
import React, { Fragment, useState } from 'react'
import { animated, config, useTransition } from 'react-spring'

import { useResizeObserver } from '../../../hooks'
import { colors, zIndex } from '../../../styles'
import { Triangle } from '../../icons'

type TooltipProps = {
  children: React.ReactNode
  className?: string
  direction?: 'right' | 'up'
  mode?: 'dark' | 'light'
  tooltip: React.ReactNode
  width?: number
}

const styles = {
  arrow: css({
    fill: colors.black,
    height: 16,
    position: 'absolute',
    stroke: colors.black,
    strokeWidth: 0.7,
    width: 16,
    zIndex: -1,
  }),
  background: css({
    backgroundColor: colors.black,
    border: `1px solid ${colors.black}`,
    borderRadius: 8,
    position: 'relative',
    zIndex: -2,
  }),
  container: css({
    position: 'relative',
  }),
  content: (width?: number) =>
    css({
      alignItems: 'center',
      backgroundColor: '#0C111D',
      borderRadius: 8,
      color: '#D0D5DD',
      display: 'flex',
      fontSize: 12,
      height: 'auto',
      justifyContent: 'center',
      overflow: 'visible',
      padding: 10,
      whiteSpace: width ? 'normal' : 'nowrap',
      width: width ? width : 'auto',
    }),
  contentContainer: css({
    backfaceVisibility: 'hidden',
    pointerEvents: 'none',
    position: 'absolute',
    zIndex: zIndex.tooltip,
  }),
}

const getPropsByDirection = (direction: TooltipProps['direction']) => {
  switch (direction) {
    case 'right':
      return {
        animateFrom: 'translate(0px, -50%)',
        animateTo: 'translate(8px, -50%)',
        arrow: {
          bottom: '50%',
          left: -10,
          transform: 'rotate(90deg) translateX(50%)',
        },
        content: {
          left: 'calc(100% + 3px)',
          top: '50%',
        },
      }
    case 'up':
    default:
      return {
        animateFrom: 'translate(-50%, 0px)',
        animateTo: 'translate(-50%, -8px)',
        arrow: {
          bottom: -10,
          left: '50%',
          transform: 'translateX(-50%)',
        },
        content: {
          bottom: 'calc(100% + 3px)',
          left: '50%',
        },
      }
  }
}

const TooltipContent = ({ children, width }: { children: React.ReactNode; width?: number }) => {
  const { ref } = useResizeObserver<HTMLDivElement>()

  return (
    <div className={styles.content(width)}>
      <div ref={ref}>{children}</div>
    </div>
  )
}

const Tooltip = ({ children, className, direction = 'up', tooltip, width }: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { animateFrom, animateTo, arrow, content } = getPropsByDirection(direction)
  const transition = useTransition(isOpen, {
    config: config.stiff,
    enter: { opacity: 1, transform: animateTo },
    from: { opacity: 0, transform: animateFrom },
    leave: { opacity: 0, transform: animateFrom },
  })

  return (
    <Fragment>
      <div
        className={css(styles.container, className)}
        onClick={() => setIsOpen(false)}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        {children}
        {transition(
          (props, item, { key }) =>
            item && (
              <animated.div
                className={css(styles.contentContainer, content)}
                key={key}
                style={props}
              >
                <div className={styles.background}>
                  <Triangle className={css(styles.arrow, arrow)} />
                  <TooltipContent width={width}>{tooltip}</TooltipContent>
                </div>
              </animated.div>
            )
        )}
      </div>
    </Fragment>
  )
}

export default Tooltip
